import React from 'react'
import { PaymentMethodEnum } from 'common/enums/PaymentMethodEnum'
import { FieldInterface } from 'common/types/entities/field-interface'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import CompanyVatFieldNew from 'publisher/pages/offer-page/components/tax-number/company-vat-field-new'
import MercadoPagoTaxNumber from 'publisher/pages/offer-page/components/tax-number/mercado-pago-tax-number'
import { usePayment } from 'publisher/store'
import { getActivePaymentMethod } from 'publisher/store/payment/paymentSelectors'

interface TaxNumberProps {
  entity: FieldInterface
}

const TaxNumber = ({ entity }: TaxNumberProps) => {
  const activePaymentMethod = usePayment(getActivePaymentMethod)

  if (typeof window === 'undefined') {
    return (
      <StyleWrapper
        margin={entity.margin}
        mobileMargin={entity.mobileMargin}
        appearance={entity.appearance}
      />
    )
  }

  switch (activePaymentMethod) {
    case PaymentMethodEnum.MercadoPagoCard:
    case PaymentMethodEnum.MercadoPagoPix:
    case PaymentMethodEnum.MercadoPagoBoleto:
    case PaymentMethodEnum.MercadoPagoBrCard:
    case PaymentMethodEnum.MercadoPagoArCard:
    case PaymentMethodEnum.MercadoPagoBrPix:
    case PaymentMethodEnum.MercadoPagoBrBoleto:
    case PaymentMethodEnum.MercadoPagoCoCard:
    case PaymentMethodEnum.MercadoPagoMxCard:
    case PaymentMethodEnum.MercadoPagoClCard:
    case PaymentMethodEnum.DlocalCard:
    case PaymentMethodEnum.DlocalPix:
    case PaymentMethodEnum.DlocalBoleto:
      return <MercadoPagoTaxNumber entity={entity} type={activePaymentMethod} />
    default:
      return <CompanyVatFieldNew entity={entity} />
  }
}

export default TaxNumber
