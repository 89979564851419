import React, { createContext, useContext } from 'react'
import CommonField from 'common/components/entities/Field/FieldUi'
import FieldWrapperUi from 'common/components/entities/Field/FieldWrapperUi'
import FieldIconUiNew from 'common/components/entities/Field/field-icon-ui'
import { FieldInterface } from 'common/types/entities/field-interface'
import FieldErrors from 'publisher/components/FieldErrors'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import { CustomTaxNumberOptionsKeys } from 'publisher/pages/offer-page/constants/custom-tax-number-options'
import { useCustomTaxNumber } from 'publisher/pages/offer-page/hooks/useCustomTaxNumber'

interface MercadoPagoTaxNumberProps {
  entity: FieldInterface
  type: CustomTaxNumberOptionsKeys
}

const MercadoPagoTaxNumberContext = createContext<FieldInterface | null>(null)

const MercadoPagoTaxNumber = ({ entity, type }: MercadoPagoTaxNumberProps) => {
  const taxNumberOptions = useCustomTaxNumber(type)
  const mobileIconColor = entity.mobileColor ?? entity.color

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { change, transform } = taxNumberOptions

    //we need clean numbers for mp sdk
    change(transform(event.target.value))
  }

  return (
    <StyleWrapper
      margin={entity.margin}
      mobileMargin={entity.mobileMargin}
      appearance={entity.appearance}
    >
      <FieldWrapperUi>
        {entity.iconClassName && (
          <FieldIconUiNew
            color={entity.color}
            mobileColor={mobileIconColor}
            fontSize={entity.fontSize}
            mobileFontSize={entity.mobileFontSize}
            className={entity.iconClassName}
          />
        )}
        <MercadoPagoTaxNumberContext.Provider value={entity}>
          <TaxNumberField
            errors={taxNumberOptions.errors}
            onChange={handleChange}
          />
          <input
            type="hidden"
            id="mercado-card-identification-number"
            value={taxNumberOptions.value}
          />
        </MercadoPagoTaxNumberContext.Provider>
        <FieldErrors errors={taxNumberOptions.errors} />
      </FieldWrapperUi>
    </StyleWrapper>
  )
}

function TaxNumberField(props: {
  errors: string[]
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}) {
  const entity = useContext(MercadoPagoTaxNumberContext)

  if (!entity) {
    return null
  }

  return (
    <CommonField
      type="text"
      padding={{
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 12,
        paddingBottom: 12,
      }} // this was hardcoded in the old InputUi
      name={entity.slug}
      hasIcon={!!entity.iconClassName}
      placeholderColor={entity.placeholderColor}
      mobilePlaceholderColor={entity?.mobilePlaceholderColor}
      placeholder={entity.placeholder}
      lineHeight={entity.lineHeight}
      mobileLineHeight={entity.mobileLineHeight}
      textAlign={entity.textAlign}
      mobileTextAlign={entity.mobileTextAlign}
      hasErrors={props.errors.length > 0}
      border={entity.border}
      fontSize={entity.fontSize}
      color={entity.color}
      mobileColor={entity.mobileColor}
      backgroundColor={entity.backgroundColor}
      mobileBackgroundColor={entity.mobileBackgroundColor}
      mobileFontSize={entity.mobileFontSize}
      mobileBorder={entity.mobileBorder}
    />
  )
}

export default MercadoPagoTaxNumber
